import React from 'react';
import { MainLayout } from '../../layouts'
import { Footer, HeroSection, LineDotContainer, Paragraph, Title } from "../../components";


import styles from './styles.module.css'

export const PrivacyPolicy = () => {
    return (
        <MainLayout>
            <HeroSection className={styles.customHero}>
                <Title colour="white" weight="bold" size="large">
                    Privacy Policy
                </Title>
            </HeroSection>
            <div className={styles.innerContent}>


                <LineDotContainer >

                    <Title>Privacy Policy</Title>
                    <div className="tb_text_wrap">
                        <p><b>PRIVACY POLICY</b></p>
                        <p>This policy explains the what, how, and why of the information we collect when you visit our website, or when you use our Services. It also explains the specific ways we use and disclose that information. We take your privacy extremely seriously, and we never sell lists or email addresses.
                        </p>
                        <p>We ask that you read this privacy policy carefully as it contains important information on:
                        </p>
                        <ul>
                            <li style={{ fontWeight: 400 }}>the personal information we collect about you
                            </li>
                            <li style={{ fontWeight: 400 }}>what we do with your information, and
                            </li>
                            <li style={{ fontWeight: 400 }}>who your information might be shared with.
                            </li>
                        </ul>
                        <p><b>Changes</b></p>

                        <p>We may change this Privacy Policy at any time and from time to time. The most recent version of the Privacy Policy is reflected by the version date located at the bottom of this Privacy Policy. All updates and amendments are effective immediately upon notice, which we may give by any means, including, but not limited to, by posting a revised version of this Privacy Policy or other notice on the Website. We encourage you to review this Privacy Policy often to stay informed of changes that may affect you, as your continued use of the Website and our services signifies your continuing consent to be bound by this Privacy Policy. Our electronically or otherwise properly stored copies of this Privacy Policy are each deemed to be the true, complete, valid, authentic, and enforceable copy of the version of this Privacy Policy which were in effect on each respective date you visited the Website.
                        </p>
                        <p><b>What information we collect</b></p>
                        <p>As data controllers we collect personal information about you (such as your name, address, date of birth, National Insurance Number, bank details, email address, phone number) when you ask us to provide our services including travel requests and insurance services. We also collect personal information when you contact us at an industry event, networking events and through our website.
                        </p>
                        <p>Occasionally we may receive information about you from other sources (such as external agencies when verifying your identity), which we will add to the information we already hold about you in order to help us comply with our obligations and provide our services to you.
                        </p>
                        <p>If you give us information on behalf of someone else, you confirm that the other person has appointed you to act on his/her behalf and has agreed that you can give consent on his/her behalf to the processing of his/her personal data; receive on his/her behalf any data protection notices; and give consent to the processing of his/her personal or sensitive data. We use information about you that you have posted on public domain, such as LinkedIn, in order for us to tailor our services to you.
                        </p>
                        <p><b>Sensitive personal information</b></p>
                        <p>We will usually not ask you to provide sensitive personal information, unless this is needed for business purposes. If we request such information, we will explain why we are requesting it and how we intend to use it. Sensitive personal information includes information relating to: your ethnic origin, your political opinions, your religious beliefs, whether you belong to a trade union, your physical or mental health or condition, your sexual orientation and whether you have committed a criminal offence. We will only collect your sensitive personal information with your explicit consent.
                        </p>
                        <p><b>How will we use the information about you?</b></p>
                        <p>If you have worked with us we will use your personal information for the purposes of administration. This may include payroll and payment processing, legal and regulatory compliance, the prevention of money laundering or terrorist financing, management of our practice, marketing our services to you, keeping accounts and for the provision of insurance and legal services. We will retain your information for&nbsp; 6 years.&nbsp;
                        </p>
                        <p>If you are part of our Calibrate Talent Pool we will gather personal information for the purposes of seeking requested employment and directly engaging with yourself. This information will be retained for 2 years, dated from the last communication.
                        </p>
                        <p>Please note that we carry out electronic identity and background checks via external search providers in order to provide services to our clients.
                        </p>
                        <p><b>Who your information might be shared with</b></p>
                        <p>
                            We may disclose your personal data to the HMRC, the I.C.O. ISO Auditors or our clients who may conduct audit or quality inspections on our practice and our client services, and who are required to maintain confidentiality in relation to our files. This could also include our travel and insurance partners.  Our clients may require certain information about you to onboard you onto their systems and provision you access to their data.
                        </p>
                        <p><b>Keeping your data secure</b></p>
                        <p>We will use technical and organisational measures to safeguard your personal data. While we will use all reasonable efforts to safeguard your personal data, you acknowledge that the use of post or emails and the internet is not entirely secure and for this reason we cannot guarantee the security or integrity of any personal data that are transferred from you or to you via the internet. If you have any particular concerns about your information, please contact us (see ‘How can you contact us?’ below).
                        </p>
                        <p>In the event of a data breach, please contact us for a&nbsp; blank data incident form within 2 days of the incident.</p>
                        <p><b>What rights do you have?</b></p>
                        <p><b>Right to request a copy of your information</b></p>
                        <p>You can request a copy of your information which we hold (this is known as a subject access request). If you would like a copy of some or it, please email us (see ‘How can you contact us?’ below) requesting a Subject Access Form, which we will send you to complete. On the form we ask for some personal information as proof of identity. We will provide this information to you within thirty (30) calendar days and usually this is provided free of charge. However, in certain circumstances we may charge a reasonable fee based on the administrative cost of providing the information requested.
                        </p>
                        <p><b>Right to correct any mistakes in your information</b></p>
                        <p>You can require us to correct any mistakes in your information which we hold free of charge. If you would like to do this, please write to us (see ‘How can you contact us?’ below), let us have enough information to identify you (eg name, address, reference number etc.), and let us know the information that is incorrect and what it should be replaced with.&nbsp; We will aim to comply with your request within one month.
                        </p>
                        <p><b>Right to have your personal data erased</b></p>
                        <p>You can contact us to request for your data to be deleted or removed where you feel that there is no compelling reason for your data to be held or processed. There may be occasions where we cannot comply with this request (for example data that we require for legal or tax purposes) but we will endeavour to comply with requests which are made.&nbsp; If you would like to do this, please write to us (see ‘How can you contact us?’ below).
                        </p>
                        <p><b>How to contact us</b></p>
                        <p>Please email our Data Protection Officer, if you have any questions about this privacy policy or the information we hold about you at mydata@calibrateconsulting.com.
                        </p>
                        <p><strong>Date: January 2022</strong></p></div>
                </LineDotContainer>

            </div>
            <Footer />
        </MainLayout>
    )
}